import { Divider } from "@mui/material"
import { useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import fp from "lodash/fp"

import type { FC } from "react"

import { useCellDataQuery, useCellViewQuery } from "features/api"
import { getRecordService } from "helpers/formatters/dataFormatters"
import type { CellType } from "types/dashboard.types"
import Plot from "widgets/plots/Plot"
import useDateRange from "helpers/hooks/useDateRange"
import DashboardContext from "./DashboardContext"

interface IColumn {
  label: string
  group?: boolean
}

interface ITable {
  columns: IColumn[]
}

const getFill: (tables: ITable[]) => string[] = fp.flow(
  fp.flatMap((table) => table.columns),
  fp.filter((column) => Boolean(column.group)),
  fp.map((column) => column.label),
  (columns) => fp.concat(columns, ["result", "table"]),
  fp.uniq,
)

interface CellProps {
  cell: CellType
}

const Cell: FC<CellProps> = ({ cell }) => {
  const {
    dashboard,
    id: deviceId,
    dispatchLayout,
    layout,
    dispatchCellState,
  } = useContext(DashboardContext)
  const dashboardId = dashboard?.id
  const { deviceDateRange, isDeviceDateRangeValid } = useDateRange()
  const params = {
    from_date: deviceDateRange?.fromDate,
    to_date: deviceDateRange?.toDate,
    less_id: deviceId,
  }

  const {
    currentData: cellView,
    isSuccess: cellViewIsSuccess,
    isFetching: cellViewIsFetching,
  } = useCellViewQuery({
    dashboardId,
    id: cell.id,
  })

  const {
    data: cellData,
    isError: cellDataIsError,
    isFetching: cellDataIsFetching,
  } = useCellDataQuery(
    {
      dashboardId,
      id: cell.id,
      params,
    },
    {
      skip: !isDeviceDateRangeValid,
    },
  )
  const { t } = useTranslation()

  const records = useMemo(
    () => (cellData || []).flatMap((table) => table.records),
    [cellData],
  )
  const recordService = useMemo(
    () => getRecordService(getFill(cellData ?? []), t),
    [cellData, t],
  )

  const isMap = cellView?.properties.type === "geo"
  const shouldRenderMap = records?.some(
    (record) => record.result === "latlon" || record.result === "location",
  )

  // Adds plot note to all line plots in layouts coming from influx.
  const view = useMemo(
    () =>
      cellView?.properties.type === "xy"
        ? {
            ...cellView,
            properties: {
              tooltip: ["plots_descriptions.AGGREGATE_NOTE"],
              ...cellView.properties,
            },
          }
        : cellView,
    [cellView],
  )

  const shouldRender =
    cellViewIsSuccess &&
    !cellDataIsError &&
    view &&
    cellData &&
    cellData.length !== 0 &&
    (isMap ? shouldRenderMap : true)

  useEffect(() => {
    dispatchLayout({
      type: "UPDATE_CELL_HEIGHT",
      payload: {
        id: cell.id,
        h: shouldRender ? cell.h : 0,
      },
    })
  }, [dispatchLayout, cell, shouldRender])

  useEffect(() => {
    dispatchCellState({
      type: "UPDATE_CELL_STATE",
      payload: {
        id: cell.id,
        isFetching: cellViewIsFetching || cellDataIsFetching,
      },
    })
  }, [cellViewIsFetching, cellDataIsFetching, dispatchCellState, cell])

  return (
    <>
      {shouldRender && layout[cell.id]?.h ? (
        <>
          <Plot
            view={view}
            data={records}
            draggable={true}
            downloadable={view.properties.type === "xy"}
            recordService={recordService}
            csvData={{
              entity: "device",
              entityId: deviceId,
              fromDate: String(deviceDateRange?.fromDate),
              toDate: String(deviceDateRange?.toDate),
            }}
          />
          <Divider />
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default Cell
