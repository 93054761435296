import { Box, Grid } from "@mui/material"
import fp from "lodash/fp"
import { useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useOutletContext } from "react-router-dom"

import { useDeviceTelemetryQuery } from "features/api"
import {
  positionInPowerlinePlotView,
  positionInTowerPlotView,
} from "helpers/config/plots"
import { getRecordService } from "helpers/formatters/dataFormatters"
import { GRID_LAYOUT_GAP, SENTRISENSE_POSITION_TIME } from "helpers/utils/constants"
import DashboardContext from "widgets/device/Dashboard/DashboardContext"
import type { DevicePositionPlotValues } from "widgets/plots/device/devicePosition/DevicePositionPlot"
import DevicePositionPlot from "widgets/plots/device/devicePosition/DevicePositionPlot"
import Plot from "widgets/plots/Plot"
import useDateRange from "helpers/hooks/useDateRange"
import { formatTelemetryData } from "widgets/plots/helpers"
import {
  alphaAmplitudePlotView,
  betaAmplitudePlotView,
  alphaDriftPlotView,
  betaDriftPlotView,
} from "helpers/config/plots/singleStatDefs"
import { getAnglesDrift, getLatestAngles } from "helpers/utils/devicePosition"
import type { DeviceDashboardOutletContext } from "types/dashboard.types"

const DevicePosition = () => {
  const { deviceId, rowHeight, dispatchPlotState } = useContext(DashboardContext)
  const { deviceDateRange, isDeviceDateRangeValid } = useDateRange()
  const { device } = useOutletContext<DeviceDashboardOutletContext>()
  const { t } = useTranslation()

  const { data, isFetching } = useDeviceTelemetryQuery(
    {
      id: deviceId,
      params: {
        aggregate: ["last"],
        aggregate_n: 360,
        field: ["derived.motion.alpha", "derived.motion.beta"],
        group: ["id", "_field"],
        from_date: deviceDateRange?.fromDate,
        to_date: deviceDateRange?.toDate,
      },
    },
    {
      skip: !isDeviceDateRangeValid,
      pollingInterval: SENTRISENSE_POSITION_TIME,
    },
  )
  const { data: amplitudeData } = useDeviceTelemetryQuery(
    {
      id: deviceId,
      params: {
        method: ["spread"],
        field: ["derived.motion.alpha", "derived.motion.beta"],
        group: ["id", "_field"],
        from_date: deviceDateRange?.fromDate,
        to_date: deviceDateRange?.toDate,
      },
    },
    {
      skip: !isDeviceDateRangeValid,
      pollingInterval: SENTRISENSE_POSITION_TIME,
    },
  )

  const lineChartView = useMemo(
    () =>
      device.settings?.installation_type === "tower"
        ? positionInTowerPlotView
        : positionInPowerlinePlotView,
    [device],
  )

  const recordService = useMemo(
    () => getRecordService(lineChartView.properties.fill, t),
    [t, lineChartView],
  )

  // Data for position line chart
  const formattedData = useMemo(
    () => formatTelemetryData(recordService, data, lineChartView),
    [data, recordService, lineChartView],
  )

  // Data for Last position
  const latestData: DevicePositionPlotValues = useMemo(
    () => getLatestAngles(data),
    [data],
  )

  // Data for amplitude single-stats
  const { alpha: alphaAmplitude, beta: betaAmplitude } = useMemo(
    () => ({
      alpha: fp.find({ _field: "derived.motion.alpha" }, amplitudeData),
      beta: fp.find({ _field: "derived.motion.beta" }, amplitudeData),
    }),
    [amplitudeData],
  )

  // Data for drift single-stats
  const { alpha: alphaDrift, beta: betaDrift } = useMemo(
    () => getAnglesDrift(data),
    [data],
  )

  useEffect(() => {
    dispatchPlotState({
      type: "UPDATE_CELL_STATE",
      payload: {
        id: lineChartView.nameKey,
        isFetching,
      },
    })
  }, [isFetching, dispatchPlotState, lineChartView])

  if (!data) {
    return <></>
  }

  return (
    <>
      {latestData && Number.isFinite(latestData._time) && (
        <Box height={"100%"} display={"flex"} flexDirection={"column"}>
          <DevicePositionPlot values={latestData} />
          <Grid container spacing={`${GRID_LAYOUT_GAP}px`} marginTop={`1px`}>
            <Grid item xs={12}>
              <Box height={rowHeight * 3}>
                <Plot
                  view={lineChartView}
                  data={formattedData}
                  downloadable={true}
                  recordService={recordService}
                  csvData={{
                    entity: "device",
                    entityId: deviceId,
                    fromDate: String(deviceDateRange?.fromDate),
                    toDate: String(deviceDateRange?.toDate),
                  }}
                />
              </Box>
            </Grid>
            <Grid container item xs={12} spacing={`${GRID_LAYOUT_GAP}px`}>
              {alphaAmplitude && (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box height={rowHeight}>
                      <Plot
                        view={alphaAmplitudePlotView}
                        data={[alphaAmplitude]}
                        recordService={recordService}
                      />
                    </Box>
                  </Grid>
                </>
              )}
              {betaAmplitude && (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box height={rowHeight}>
                      <Plot
                        view={betaAmplitudePlotView}
                        data={[betaAmplitude]}
                        recordService={recordService}
                      />
                    </Box>
                  </Grid>
                </>
              )}
              {!fp.isNil(alphaDrift?._value) && (
                <Grid item xs={12} sm={6} md={3}>
                  <Box height={rowHeight}>
                    <Plot
                      view={alphaDriftPlotView}
                      data={[alphaDrift]}
                      recordService={recordService}
                    />
                  </Box>
                </Grid>
              )}
              {!fp.isNil(betaDrift?._value) && (
                <Grid item xs={12} sm={6} md={3}>
                  <Box height={rowHeight}>
                    <Plot
                      view={betaDriftPlotView}
                      data={[betaDrift]}
                      recordService={recordService}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default DevicePosition
