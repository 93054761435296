import { useContext, useEffect, useMemo } from "react"
import type { FC } from "react"
import { useTranslation } from "react-i18next"

import { useDeviceTelemetryQuery } from "features/api"
import { getRecordService } from "helpers/formatters/dataFormatters"
import useDevices from "helpers/hooks/useDevices"
import useDateRange from "helpers/hooks/useDateRange"
import { getCellHeight } from "helpers/utils/dashboards"
import DashboardContext from "widgets/device/Dashboard/DashboardContext"
import type { IPlotReqConfig, IPlotView, RecordService } from "types/dashboard.types"
import Plot from "../Plot"
import { formatTelemetryData, getFill } from "../helpers"

interface DeviceTelemetryPlotProps {
  view: IPlotView
  reqConfig: IPlotReqConfig
  draggable?: boolean
  downloadable?: boolean
}

const DeviceTelemetryPlot: FC<DeviceTelemetryPlotProps> = ({
  view,
  reqConfig,
  draggable,
  downloadable,
}) => {
  const { t } = useTranslation()
  const {
    deviceId,
    dispatchPlotLayout,
    dispatchPlotState,
    config: dashboardConfig,
  } = useContext(DashboardContext)
  const { deviceDateRange, isDeviceDateRangeValid } = useDateRange()
  const { devsWRelsById } = useDevices()
  const { aggregate, aggregate_n, fields, group, method, aggregate_every } = reqConfig

  const fromDate = useMemo(
    () =>
      view.nameKey === "LOCATION" && devsWRelsById?.[deviceId]?.device.activated_at
        ? new Date(devsWRelsById[deviceId].device.activated_at as number).toISOString()
        : deviceDateRange?.fromDate,
    [devsWRelsById, deviceDateRange, deviceId, view.nameKey],
  )

  const { data, isFetching } = useDeviceTelemetryQuery(
    {
      id: deviceId,
      params: {
        aggregate,
        aggregate_n,
        method,
        group,
        aggregate_every,
        field: fields,
        from_date: fromDate,
        to_date: deviceDateRange?.toDate,
      },
    },
    {
      skip: !isDeviceDateRangeValid || !fromDate || !fields || !fields.length,
    },
  )

  const recordService: RecordService = useMemo(() => {
    // What should we use as fill if we are using pivot?
    const fill = getFill(view, reqConfig)
    return getRecordService(fill, t)
  }, [reqConfig, t, view])

  const formattedData = useMemo(
    () => formatTelemetryData(recordService, data, view),
    [data, view, recordService],
  )

  const shouldRender = data && data.length !== 0

  useEffect(() => {
    dispatchPlotLayout({
      type: "UPDATE_CELL_HEIGHT",
      payload: {
        id: view.nameKey,
        h: shouldRender ? getCellHeight(view.nameKey, dashboardConfig.type) : 0,
      },
    })
  }, [dispatchPlotLayout, view, shouldRender, dashboardConfig.type])

  useEffect(() => {
    dispatchPlotState({
      type: "UPDATE_CELL_STATE",
      payload: {
        id: view.nameKey,
        isFetching,
      },
    })
  }, [isFetching, dispatchPlotState, view])

  return (
    <>
      {formattedData && formattedData.length > 0 && (
        <Plot
          data={formattedData}
          view={view}
          draggable={draggable}
          downloadable={downloadable}
          recordService={recordService}
          csvData={{
            entity: "device",
            entityId: deviceId,
            fromDate: String(deviceDateRange?.fromDate),
            toDate: String(deviceDateRange?.toDate),
          }}
        />
      )}
    </>
  )
}

export default DeviceTelemetryPlot
