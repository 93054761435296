import fp from "lodash/fp"

import type { IPlotDef } from "types/dashboard.types"

import { plotDefBase } from "./base"

export const mapView = {
  name: "Location",
  nameKey: "LOCATION",
  properties: {
    allowPanAndZoom: true,
    showNoteWhenEmpty: false,
    type: "geo",
    zoom: 6,
    detectCoordinateFields: false,
    useS2CellID: false,
    latLonColumns: {
      lat: {
        key: "field",
        column: "lat",
      },
      lon: {
        key: "field",
        column: "lon",
      },
    },
    layers: [
      {
        type: "pointMap",
        colorDimension: { label: "value" },
        colorField: "_value",
        isClustered: false,
      },
    ],
  },
}

export const mapPlotDef: IPlotDef = fp.merge(plotDefBase, {
  view: mapView,
  downloadable: false,
  reqConfig: {
    fields: ["lat", "lon"],
    method: ["last"],
    group: ["id", "_field"],
  },
})
