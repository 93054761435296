import { useMemo, type CSSProperties } from "react"
import Box from "@mui/material/Box"
import { Stack } from "@mui/material"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import { useTranslation } from "react-i18next"

import type { EntityType } from "types/common.types"
import type { RecordType, CellViewType, RecordService } from "types/dashboard.types"

import { getPlotName } from "helpers/utils/translations"
import { formatPlotTitleTooltip } from "helpers/utils/plots"
import { PlotContainer } from "../styled/containers"
import GeoPlot from "./geo/GeoPlot"
import GiraffePlot from "./GiraffePlot"
import SingleStat from "./SingleStat"
import PieChart from "./recharts/PieChart"
import GaugeChart from "./recharts/gauge/GaugeChart"
import PlotTitle from "./PlotTitle"
import PlotCsvDownload from "./PlotCsvDownload"

interface PlotProps {
  view: CellViewType
  data: RecordType[]
  recordService?: RecordService
  draggable?: boolean
  downloadable?: boolean
  showUnit?: boolean
  styles?: CSSProperties
  csvData?: {
    entity: EntityType
    entityId: number
    fromDate: string
    toDate: string
  }
}

export default function Plot({
  view,
  data,
  recordService,
  styles,
  draggable = false,
  downloadable = false,
  showUnit = false,
  csvData,
}: PlotProps) {
  const InnerPlot = () => {
    const type = view.properties.type
    switch (type) {
      case "geo":
        return <GeoPlot data={data} />
      case "single-stat":
        return <SingleStat view={view} data={data} />
      case "gauge":
        return <GaugeChart view={view} data={data} />
      case "pie":
        return <PieChart view={view} data={data} />
      default:
        return <GiraffePlot view={view} data={data} recordService={recordService} />
    }
  }
  const { t } = useTranslation()
  const { nameKey, unit, tooltip } = getPlotName(view, showUnit)
  const tooltipContent = useMemo(
    () => (tooltip ? formatPlotTitleTooltip(tooltip, t) : undefined),
    [t, tooltip],
  )

  return (
    <>
      {data && view && (
        <>
          <PlotContainer maxWidth="xl" type={view.properties.type} sx={{ ...styles }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: "0.5rem" }}>
              <PlotTitle
                title={t([`device_information.${nameKey}`, view.name])}
                unit={unit}
                showUnit={showUnit}
                tooltipContent={tooltipContent}
              />
              <Stack direction={"row"} gap={1} alignItems={"center"} height={"40px"}>
                {downloadable && csvData && (
                  <PlotCsvDownload plotData={data} csvData={{ ...csvData, nameKey }} />
                )}
                {draggable && (
                  <DragIndicatorIcon
                    className="dragIcon"
                    data-testid="drag"
                    cursor="move"
                  />
                )}
              </Stack>
            </Box>
            <Box
              sx={{
                height: "calc(100% - 1rem)",
              }}
            >
              {InnerPlot()}
            </Box>
          </PlotContainer>
        </>
      )}
    </>
  )
}
