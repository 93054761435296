import { useCallback, useState } from "react"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { IconButton, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { CSVLink } from "react-csv"
import fp from "lodash/fp"
import { DateTime } from "luxon"
import type { Headers } from "react-csv/lib/core"

import type { IRecordWTimestamp, RecordType } from "types/dashboard.types"
import type { EntityType } from "types/common.types"

import { formatFilename } from "helpers/utils/common"
import { formatXYPlotData } from "helpers/formatters/dataFormatters"
import { CSV_COLUMN_KEYS } from "helpers/utils/constants"

interface PlotCsvDownloadProps {
  plotData: RecordType[]
  csvData: {
    nameKey: string
    entity: EntityType
    entityId: number
    fromDate: string
    toDate: string
  }
}

const PlotCsvDownload = ({ plotData, csvData }: PlotCsvDownloadProps) => {
  const { t } = useTranslation()
  const { entity, entityId, nameKey, fromDate, toDate } = csvData
  const [data, setData] = useState<RecordType[]>([])
  const [headers, setHeaders] = useState<Headers>()
  const [filename, setFilename] = useState<string>()

  const handleClick = useCallback(() => {
    const formattedPlotData = formatXYPlotData(plotData)

    const formattedData = fp.flow(
      fp.map((data: IRecordWTimestamp) => fp.pick(CSV_COLUMN_KEYS, data)),
      fp.map((data) => ({
        ...data,
        _value: Number(data._value),
        _time: DateTime.fromJSDate(data._time as Date).toFormat("yyyy-MM-dd HH:mm"),
      })),
    )(formattedPlotData)

    const formattedHeaders = fp.flow(
      fp.pick(CSV_COLUMN_KEYS),
      fp.keys,
      fp.map((key) => ({ label: key, key })),
    )(plotData[0])

    const formattedFromDate = DateTime.fromISO(fromDate).toFormat("yyyy-MM-dd HH:mm:ss")
    const formattedtoDate = DateTime.fromISO(toDate).toFormat("yyyy-MM-dd HH:mm:ss")

    const formattedFilename = formatFilename(
      `${t(`entities.${entity.toUpperCase()}`)}_${entityId}_${t(
        `device_information.${nameKey}`,
      )}_${formattedFromDate}_${t("generic.TO")}_${formattedtoDate}`,
      "csv",
    )

    setData(formattedData)
    setHeaders(formattedHeaders)
    setFilename(formattedFilename)
  }, [plotData, fromDate, toDate, t, entity, entityId, nameKey])

  return (
    <CSVLink onClick={handleClick} data={data} headers={headers} filename={filename}>
      <Tooltip title={t("table.DOWNLOAD_CSV")}>
        <IconButton sx={{ "&:hover": { color: "primary.main" } }}>
          <CloudDownloadIcon />
        </IconButton>
      </Tooltip>
    </CSVLink>
  )
}

export default PlotCsvDownload
